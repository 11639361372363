<template>
  <div class="">
    <div class="columns is-justify-content-space-between">
      <div class="column">
        <div class="is-flex is-align-items-center">
          <div>
            <h1 class="is-size-4 has-text-weight-medium">
              Inbound Calls
            </h1>
            <h4 class="has-text-primary is-capitalized">{{ type }}</h4>
          </div>
          <b-button  @click="getCalls"
          class="button ml-3" title="refresh">
            <b-icon
            icon="refresh"
            ></b-icon>
          </b-button>
        </div>
      </div>
      <div class="column">
        <div class="is-flex is-align-items-center">
          <b-button
            tag="router-link"
            :to="{name: $route.name, query: {type: 'received'}}"
            class="m-1"
            icon-left="call-received"
            :class="{
              'is-primary': type === 'received'
            }">
            Received
          </b-button>
          <b-button
            tag="router-link"
            :to="{name: $route.name, query: {type: 'voicemail'}}"
            class="m-1"
            icon-left="voicemail"
            :class="{
              'is-primary': type === 'voicemail'
            }">
            Voicemail
          </b-button>
        </div>
      </div>
    </div>
      <b-table
      :data="calls"
      paginated
      :per-page="perPage"
      :current-page.sync="currentPage"
      :loading="loading"
      default-sort="callStartTime"
      default-sort-direction="desc">
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Search..."
              icon="magnify"/>
          </template>
          <template v-slot="props">
            <div  v-if="column.field === 'callerNumber'">
              <div v-if="props.row.user">
                <h4>
                  <strong>
                    {{
                      [
                      props.row.user.firstName,
                      props.row.user.lastName
                      ].join(' ')
                    }}
                  </strong>
                </h4>
                <h5 class="has-text-grey">{{ props.row[column.field] }}</h5>
              </div>
              <h4 v-else> <strong>{{ props.row[column.field] }}</strong> </h4>
            </div>
            <div v-else-if="column.field === 'action'">
              <b-button
              size="is-small"
              icon-left="volume-high"
              type="is-primary"
              @click="listen(props.row)">
                Listen
              </b-button>
            </div>
            <div v-else>
              {{ props.row[column.field] }}
            </div>
          </template>
        </b-table-column>
      </template>
      <template #empty>
        <div class="has-text-centered">No {{ type }} call</div>
      </template>
    </b-table>
    <inbound-call ref="listenToCall" :call="currentCall" />
  </div>
</template>

<script>
import { getInboundCalls } from '@/api/voice';
import InboundCall from '@/components/InboundCall';

export default {
  name: 'InboundCalls',
  metaInfo() {
    return {
      title: `Inbound calls (${this.type})`,
    };
  },
  components: { InboundCall },
  data() {
    return {
      loading: false,
      calls: [],
      columns: [
        {
          field: 'callStartTime',
          label: 'Time',
          sortable: true,
          width: 200,
        },
        {
          field: 'callerNumber',
          label: 'Caller',
          searchable: true,
          width: 300,
        },
        {
          field: 'destinationNumber',
          label: 'Destination',
          width: 300,
        },
        {
          field: 'callerCarrierName',
          label: 'Carrier',
          width: 100,
        },
        {
          field: 'callSessionState',
          label: 'Session status',
          width: 200,
        },
        {
          field: 'action',
          label: '',
        },
      ],
      perPage: 10,
      currentPage: 1,
      currentCall: null,
    };
  },
  computed: {
    type() {
      const type = this.$route.query.type ?? 'received';
      return ['received', 'voicemail'].includes(type) ? type : 'received';
    },
  },
  methods: {
    getCalls() {
      this.loading = true;
      getInboundCalls(this.type).then(response => {
        this.calls = response.data;
      })
        .catch(error => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    listen(call) {
      this.currentCall = call;
      this.$refs.listenToCall.open();
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(type) {
        this.getCalls(type);
      },
    },
  },
};

</script>
