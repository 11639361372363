<template>
  <b-modal
    v-if="call"
    v-model="modal"
    :width="300"
    @close="$emit('close')">
      <div class="card">
        <div class="card-header">
          <div v-if="call.user">
            <h4 class="card-header-title">
              Call from {{ [call.user.firstName, call.user.lastName].join(' ') }}
            </h4>
            <p class="ml-5">{{ call.callerNumber }}</p>
          </div>
          <h4 v-else class="card-header-title">
            Call from {{ call.callerNumber }}
          </h4>
        </div>
        <div class="card-content">
          <audio-player :source="call.recordingUrl" autoplay />
        </div>
      </div>
  </b-modal>
</template>

<script>
import AudioPlayer from './AudioPlayer';

export default {
  name: 'InboundCall',
  components: { AudioPlayer },
  data() {
    return {
      modal: false,
    };
  },
  props: {
    call: Object,
  },
  methods: {
    open() {
      this.modal = true;
    },
    close() {
      this.modal = false;
    },
  },
};
</script>
